



























































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import styled from 'vue-styled-components'
import { apiWebNurseryMePath } from '../rails/routes'
import CsButton from '~/components/CsButton.vue'
import ElwDialog from '~/components/ElwDialog'

const DialogFooter = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  .cs-button {
    margin-left: 20px;
  }
`

const DialogTitle = styled.span`
  img {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin: -6px 8px 0 0;
  }
`

@Component({
  components: {
    CsButton,
    DialogFooter,
    DialogTitle,
    ElwDialog
  }
})
export default class Sidebar extends Vue {
  @Prop(Boolean) readonly isCollapse!: boolean

  userType = ''
  confirmExitVisible = false
  openManualLink (): void {
    window.open('https://room.lookmee.jp/view/knowledgebase/766', '_blank')
  }

  async mounted (): Promise<void> {
    const me = await this.$axios.$get<{
      id: number;
      userTitle: string;
      nurseryId: number;
      name: string;
      isBeta: boolean;
      isReferenceRequired: boolean;
      userType: string;
      permissions: string[];
    }>(apiWebNurseryMePath())
    this.userType = me.userType
  }
}
